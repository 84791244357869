import { InputField } from '@eventbrite/eds-input-field';
import { gettext } from '@eventbrite/i18n';
import { isAnEmail } from '@eventbrite/validators';
import classNames from 'classnames';
import React, { useState } from 'react';
import { formatEmailRedirectPath } from '../modules/utils';
import './signupForm.scss';

interface SignupFormProps {
    buttonText: string;
    signupGridClasses: string;
    showMktgFormClass?: boolean;
    inputFieldClasses?: string;
    buttonUrl: string;
}
const SignupForm = (props: SignupFormProps) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(gettext(''));
    const redirectPath = formatEmailRedirectPath(props.buttonUrl, email);
    const {
        buttonText,
        signupGridClasses,
        inputFieldClasses,
        showMktgFormClass,
    } = props;

    const submitForm = (event: any) => {
        if (!isAnEmail(email) || email === '') {
            // Prevent the action (redirect) on the form from taking place if the email is incorrect
            event?.preventDefault();
            setEmailError(gettext('Please enter a valid email address.'));
        }
    };

    const onFormChange = (value: string) => {
        setEmail(value);
        // Reset the email error if the user starts typing again
        setEmailError(gettext(''));
    };

    return (
        <form
            name="wagtailSignupForm"
            onSubmit={submitForm}
            action={redirectPath}
            className={classNames({
                'responsive-form': true,
                'mktg-responsive-form': showMktgFormClass,
            })}
        >
            <div className={inputFieldClasses}>
                <InputField
                    style="dynamic"
                    id="email"
                    name="email"
                    label={gettext('Email')}
                    value={email}
                    onChange={onFormChange}
                    required={true}
                />
                {emailError && <p className="email-error">{emailError}</p>}
            </div>
            <div className={signupGridClasses}>
                <input
                    type="submit"
                    className="eds-btn eds-btn--button eds-btn--fill eds-btn--responsive mktg-btn-block"
                    value={buttonText}
                />
            </div>
        </form>
    );
};
export default SignupForm;
