import * as React from 'react';

const HeadphoneSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="headphone_svg__eds-icon--headphone_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="headphone_svg__eds-icon--headphone_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12.5h-1V11h-2V9h-1c0-3.3-2.7-6-6-6S6 5.7 6 9H5v2H3v1.5H2v1h1V15h2v2h3V9H7c0-2.7 2.2-5 5-5s5 2.2 5 5h-1v8h2v1c0 .8-.7 1.5-1.5 1.5h-1.6c-.2-.9-1-1.5-1.9-1.5-1.1 0-2 .9-2 2s.9 2 2 2c.9 0 1.7-.6 1.9-1.5h1.6c1.4 0 2.5-1.1 2.5-2.5v-3h2v-1.5h1v-1zM4 14h1v-2H4v2zm2 2h1v-6H6v6zm7 5c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1zm4-5h1v-6h-1v6zm2-2h1v-2h-1v2z"
        />
    </svg>
);

HeadphoneSvg.displayName = 'HeadphoneSvg';
export default HeadphoneSvg;
