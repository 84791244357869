import * as React from 'react';

const TicketSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="ticket_svg__eds-icon--ticket_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="ticket_svg__eds-icon--ticket_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 21h-3c-.4-1.7-1.6-3-4-3s-3.6 1.3-4 3H5V3h3c.4 1.7 1.5 3.5 4 3.5s3.6-1.8 4-3.5h3v18zM15 2s0 3.5-3 3.5S9 2 9 2H4v20h5s0-3 3-3 3 3 3 3h5V2h-5z"
        />
        <g
            id="ticket_svg__eds-icon--ticket_dashes"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M7 11.5h2v1H7zM11 11.5h2v1h-2zM15 11.5h2v1h-2z" />
        </g>
    </svg>
);

TicketSvg.displayName = 'TicketSvg';
export default TicketSvg;
