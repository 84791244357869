import * as React from 'react';

const BoltSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="bolt_svg__eds-icon--bolt_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="bolt_svg__eds-icon--bolt_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 6v4h6.3L13 18v-4H6.7L11 6zm1-4L5 15h7v7l7-13h-7V2z"
        />
    </svg>
);

BoltSvg.displayName = 'BoltSvg';
export default BoltSvg;
