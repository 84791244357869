import * as React from 'react';

const CreditCardBackSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="credit-card-back_svg__eds-icon--credit-card-back_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="credit-card-back_svg__eds-icon--credit-card-back_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 11h18v7H3v-7zm0-5h18v3H3V6zM2 19h20V5H2v14z"
        />
        <g
            id="credit-card-back_svg__eds-icon--credit-card-back_dashes"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M4 15h2v1H4zM8 15h6v1H8z" />
        </g>
    </svg>
);

CreditCardBackSvg.displayName = 'CreditCardBackSvg';
export default CreditCardBackSvg;
