import { gettext } from '@eventbrite/i18n';
import React from 'react';

const tosLink = '/tos/';
const privacyPolicyLink = '/privacypolicy/';
const communityGuidelinesLink = '/community-guidelines/';
const loginLink = '/login';

interface DisclaimerProps {
    buttonText: string;
}
const DisclaimerText = ({ buttonText }: DisclaimerProps) => (
    <div className="eds-l-mar-vert-2 eds-l-pad-hor-2">
        <p className="eds-text-bs eds-text--center eds-text-color--grey-700 disclaimer-text">
            {gettext(
                "By clicking '%(buttonText)s', I confirm that I agree with the Eventbrite",
                {
                    buttonText,
                },
            )}
            &nbsp;
            <a href={tosLink}>{gettext('terms of service')}</a>, &nbsp;
            <a href={privacyPolicyLink}>{gettext('privacy policy')}</a>
            {gettext(', and ')}&nbsp;
            <a href={communityGuidelinesLink}>
                {gettext('community guidelines')}
            </a>
            .&nbsp;
            <span>
                {gettext('Already a member?')} &nbsp;
                <a href={loginLink}>{gettext('Log in')}</a>.
            </span>
        </p>
    </div>
);
export default DisclaimerText;
