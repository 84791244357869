import * as React from 'react';

const FriendsSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="friends_svg__eds-icon--friends_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="friends_svg__eds-icon--friends_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M5.8 19.4c3.9 2.1 8.7 2.1 12.7.1-.9-2.7-3.4-4.7-6.3-4.7s-5.5 1.8-6.4 4.6zm1.5-3.9c-.3-.1-.5-.1-.8-.1-1.4 0-2.7.8-3.3 2.1.6.5 1.1.9 1.7 1.3.5-1.3 1.3-2.4 2.4-3.3zm8.7-.7c.6-.3 1.3-.4 1.9-.4 2.1 0 3.9 1.4 4.5 3.4-2.8 2.8-6.5 4.2-10.2 4.2-3.7 0-7.4-1.4-10.2-4.2.6-2 2.4-3.4 4.5-3.4.6 0 1.3.1 1.9.4 1.1-.6 2.4-1 3.8-1 1.4-.1 2.7.3 3.8 1zm1.1.7c.3-.1.6-.1.9-.1 1.4 0 2.7.8 3.3 2.1-.6.5-1.2 1-1.9 1.4-.4-1.3-1.3-2.5-2.3-3.4z"
        />
        <g
            id="friends_svg__eds-icon--friends_circles"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
        >
            <path d="M18.5 6.7c0 .4 0 .7-.1 1 1 .3 1.7 1.3 1.7 2.4 0 1.4-1.1 2.5-2.5 2.5-.7 0-1.4-.3-1.9-.8-.3.2-.6.4-.9.5.6.8 1.7 1.4 2.8 1.4 2 0 3.5-1.6 3.5-3.5 0-1.8-1.1-3.1-2.6-3.5zM6.8 12.6c-1.4 0-2.5-1.1-2.5-2.5C4.3 9 5 8 6 7.7c-.1-.3-.1-.7-.1-1-1.5.4-2.7 1.8-2.7 3.4 0 2 1.6 3.5 3.5 3.5 1.1 0 2.1-.5 2.8-1.4-.2-.1-.5-.2-.8-.4-.5.5-1.2.8-1.9.8z" />
            <path d="M17.4 6.6c0-2.8-2.3-5.1-5.1-5.1S7.1 3.8 7.1 6.6s2.3 5.1 5.1 5.1 5.2-2.2 5.2-5.1zm-5.2 4.1c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1z" />
        </g>
    </svg>
);

FriendsSvg.displayName = 'FriendsSvg';
export default FriendsSvg;
