import * as React from 'react';

const GlobeSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="globe_svg__eds-icon--globe_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="globe_svg__eds-icon--globe_base"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M17.4 16.1c3.8-3.8 3.8-10 0-13.8l-.7.7c3.4 3.4 3.4 9 0 12.4-3.4 3.4-9 3.4-12.4 0l-.7.7c1.8 1.8 3.9 2.7 6.4 2.8v2H8.5v1h4v-1H11v-2c2.5 0 4.6-1 6.4-2.8z"
        />
        <path
            id="globe_svg__eds-icon--globe_globe"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#231F20"
            d="M10.5 17c1.9 0 3.8-.7 5.3-2.2 2.9-2.9 2.9-7.7 0-10.6C14.3 2.7 12.4 2 10.5 2s-3.8.7-5.3 2.2c-2.9 2.9-2.9 7.7 0 10.6 1.5 1.4 3.4 2.2 5.3 2.2zm-4.3-6.8l3.6 3.6c-.7.2-1.5.3-2.2.3-.5 0-1-.1-1.5-.2-.3-1.2-.3-2.5.1-3.7zm8.1-.4l-4.2-4.2c1-.5 2.1-.8 3.3-.8.5 0 1 .1 1.5.2.4 1.7.2 3.4-.6 4.8zm-.5 1c-.3.4-.6.8-.9 1.1-.6.6-1.3 1.1-2.1 1.5L6.6 9.2c.4-.8.9-1.5 1.5-2.1.4-.4.7-.7 1.1-.9l4.6 4.6zm-6.6 4.3h.4c1 0 2-.2 3-.5l1.3 1.3c-.5 0-.9.1-1.4.1-1.2 0-2.3-.3-3.3-.9zm7.9-1c-.6.6-1.4 1.1-2.1 1.4l-1.4-1.4c.7-.4 1.4-.9 2.1-1.5.4-.4.7-.7.9-1.1l1.4 1.4-.9 1.2zm1.4-2.1l-1.4-1.4c.7-1.4 1.1-2.9 1-4.4.6 1 .9 2.1.9 3.3 0 .9-.2 1.7-.5 2.5zm-2.7-8.1h-.4c-1.4 0-2.8.3-4 1L8 3.5c.8-.3 1.6-.5 2.5-.5 1.2 0 2.3.3 3.3.9zm-7.9 1c.4-.4.7-.7 1.2-.9l1.4 1.4c-.4.3-.8.6-1.1 1-.6.6-1.1 1.3-1.5 2L4.5 7c.3-.7.8-1.5 1.4-2.1zM4.1 8.1l1.3 1.3c-.4 1.1-.6 2.2-.5 3.4-.6-1-.9-2.1-.9-3.3 0-.5.1-.9.1-1.4z"
        />
    </svg>
);

GlobeSvg.displayName = 'GlobeSvg';
export default GlobeSvg;
