import * as React from 'react';

const BarGraphSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="bar-graph_svg__eds-icon--bar-chart__svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="bar-graph_svg__eds-icon--bar-chart__base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 11h2v10h-2V11zm7-8h2v18h-2V3zM4 7h2v14H4V7zm13 14h-3V10h-4v11H7V6H3v15H2v1h20v-1h-1V2h-4v19z"
        />
    </svg>
);

BarGraphSvg.displayName = 'BarGraphSvg';
export default BarGraphSvg;
