/*
 __________________________________
/ This is a good place for general \
\ purpose validators               /
 ----------------------------------
        \   ^__^
         \  (oo)\_______
            (__)\       )\/\
                ||----w |
                ||     ||

In this context:

    VALIDATOR (noun) -  A function that takes returns a boolean describing the validity of a given value.
                        Validators' names should be predicates.

    GENERATOR (noun) -  A function that takes parameters then MAKEs and returns a VALIDATOR.
                        Generators' names should start with `make` for consistency.

Most validators should treat empty or missing values as VALID.

*/
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { SingleValueValidator } from './types';

/**
 * Validator: Tests that the value is present and not empty. Useful for `required`.
 */
export const isProvided: SingleValueValidator<any> = (value) => {
    if (isString(value)) {
        return !!value.trim();
    }

    if (isBoolean(value)) {
        return value;
    }

    return !isEmpty(value);
};

/**
 * Validator: Ensures that the inputted string is not entirely spaces.
 */
export const hasContent: SingleValueValidator<string> = (value) =>
    !value || value.trim() !== '';

/**
 * Generator: Makes an isOneOf validator.
 * Validator: Tests that the value is one of the elements in `list`
 */
export const makeIsOneOf =
    <ItemType>(
        list: Array<ItemType | undefined> = [],
    ): SingleValueValidator<ItemType> =>
    (value) =>
        list.includes(value);

/**
 * Generator: Makes a matchesRegex validator.
 * Validator: Tests that the value satisfies the `regexPattern`s `test` method.
 */
export const makeMatchesRegex =
    <ValueType>(regexPattern: RegExp): SingleValueValidator<ValueType> =>
    (value) =>
        !value || regexPattern.test(`${value}`);

/**
 * Validator: Tests that the value is an integer
 */
export const isAnInteger = makeMatchesRegex<string | number>(
    /^(-{1})?[0-9]+(\.0*)?(?:e[+-][0-9]+)?$/,
);

/**
 * Validator: Tests that the value is an integer
 */
export const isANumber = makeMatchesRegex<string | number>(
    /^(?:[0-9]+|[0-9]*\.[0-9]+)(?:e[+-][0-9]+)?$/,
);

/**
 * Validator: Tests that the value is a whole number
 */
export const isAWholeNumber: SingleValueValidator<string | number> = (value) =>
    !value || (isAnInteger(value) && parseInt(`${value}`, 10) >= 0);

/**
 * Generator: Makes a hasMaxLength validator.
 * Validator: Tests that the value's length is equal to or shorter than `maxLength`.
 */
export const makeHasMaxLength =
    <ValueType extends { length: number }>(
        maxLength: number,
    ): SingleValueValidator<ValueType> =>
    (value) =>
        !value || value.length <= maxLength;

/**
 * Generator: Makes a hasMinLength validator.
 * Validator: Tests that the value's length is equal to or longer than `minLength`.
 */
export const makeHasMinLength =
    <ValueType extends { length: number }>(
        minLength: number,
    ): SingleValueValidator<ValueType> =>
    (value) =>
        !value || value.length >= minLength;

/**
 * Generator: Makes an isInRange validator.
 * Validator: Tests that the value is within a range (inclusive)
 */
export const makeIntIsInRange =
    (low: number, high: number): SingleValueValidator<string | number> =>
    (value) => {
        if (!value) {
            return true;
        }

        const intValue =
            typeof value === 'number' ? value : parseInt(value, 10);

        return intValue >= low && intValue <= high;
    };
